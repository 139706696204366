// import { dots } from './dots';
import mat4 from 'gl-mat4';
import vert from './sky.vert';
import frag from './sky.frag';

const random = (min = 0, max = 1) => Math.random() * (max - min) + min;

const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const range = (val = 1) => random(-val, val);

const getEmptyArr = (count) => Array(count).fill(0);

const getRandomArray = (count, n = 0, m = 1) => getEmptyArr(count).map(() => random(n, m));

const hit = (val) => {
  if (val < 2 || val == null) {
    val = 2;
  }
  return randomInt(0, val - 1) === 0;
};

export const createSkyCommand1 = (regl) => {
  const count = 5000;
  const position = [];
  const offset = [];
  const alpha = [];
  const size = [];
  const speed = [];

  for (let i = 0; i < count; i += 1) {
    const kake = hit(2) ? random(0.00005, 0.01) : random(0.1, 0.25);

    const x = range(1) * kake;
    const y = range(1);
    const z = range(1) * kake;

    position.push([x, y, z]);
    offset.push([y, x, range(1)]);

    alpha.push(random(0.25, 1));
    size.push(random(0.25, 2));
    speed.push(1);
  }
  const view = [
    -0.969245970249176,
    0.08199267834424973,
    -0.23203319311141968,
    0,
    0,
    0.9428644776344299,
    0.3331764340400696,
    0,
    0.24609388411045074,
    0.32292991876602173,
    -0.9138676524162292,
    0,
    -5.551115123125783e-17,
    -0,
    -1.7999999523162842,
    1,
  ];

  const projection = [
    -2.0151357650756836,
    0,
    0,
    0,
    0,
    -3.677814483642578,
    0,
    0,
    0,
    0,
    -1.0000040531158447,
    -1,
    0,
    0,
    -0.20000040531158447,
    0,
  ];

  return regl({
    vert,
    frag,
    attributes: {
      position,
      offset,
      p_alpha: alpha,
      p_size: size,
      p_speed: speed,
      a_random0: getRandomArray(count),
      a_random1: getRandomArray(count),
    },
    uniforms: {
      time: ({ time, view, projection }) => {
        // console.log(view, projection);
        return time;
      },
      size: 1.5,
      speed: 30,
      zure: 2.65,
      radius: 0.4,
      alpha: 1,
      view,
      projection,

      p: regl.prop('p'),
      // view: () => mat4.lookAt([], [1.8, 0, 1], [0, 0, 0], [0, 1, 0]),
      // projection: ({ viewportWidth, viewportHeight }) =>
      //   mat4.perspective([], 100, viewportWidth / viewportHeight, 0.1, 5000),
    },
    count: position.length,
    primitive: 'points',
    depth: { enable: true },
    blend: {
      enable: true,
      func: {
        srcRGB: 'src alpha',
        srcAlpha: 'src alpha',
        dstRGB: 'dst alpha',
        dstAlpha: 'dst alpha',
      },
    },
  });
};
