<template lang="pug">
.stats.fixed
  span.fps(ref='stats') 0
  span.p {{ animation.p }}
.p-controller.absolute(ref='pController')
canvas.canvas(ref='canvas')
  img(ref='img', src='~@/assets/star.png')
.tips.fixed
  span 下划
</template>

<script>
window.__DEBUG__ = false;

import createREGL from 'regl';
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import mat4 from 'gl-mat4';

import Controls from 'controls-state';
import Gui from 'controls-gui';
import nextPow2 from 'next-pow-2';

import { addScrollListener } from '@zhinan-oppo/scroll-handle';
import { createBlurCommand } from '@/commands/blur';
import { createBlitCommand } from '@/commands/blit';
import { createLumaCommand } from '@/commands/pick-luma';
import { createTextCommand } from '@/commands/star-text';
import { createSkyCommand1 } from '@/commands/particle-sky/sky.js';

import bloomFrag from '@/shaders/bloom.frag';

export default {
  name: 'ParticleText1',
  setup() {
    const canvas = ref(null);
    const img = ref(null);
    const stats = ref(null);
    const pController = ref(null);

    const DURATION = 8;
    const animation = reactive({
      trailState: 0,
      duration: DURATION,
      z: 700,
      timeScale: 0.5,
      // p: 1,
      p: 0,
    });

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    onMounted(async () => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      let raf;
      const changeP = (delta = 1) => {
        window.cancelAnimationFrame(raf);

        animation.p += delta * (1 / 90);
        if (animation.p > 1) {
          animation.p = 1;
        } else if (animation.p < 0) {
          animation.p = 0;
        } else {
          raf = window.requestAnimationFrame(() => changeP(delta));
        }
      };

      addScrollListener(pController.value, {
        start: 'top',
        end: 'bottom',
        forceInViewBoundary: true,
        handlers: {
          inView({ distance, total }) {
            // animation.p = distance / total;
            const p = distance / total;
            if (p > 0.1) {
              changeP(1);
            } else {
              changeP(-1);
            }
          },
        },
      });

      const state = Gui(
        Controls({
          bloom: Controls.Section(
            {
              strength: Controls.Slider(5, { min: 0, max: 20, step: 0.1 }),
              radius: Controls.Slider(1, {
                mapping: (x) => Math.pow(2, x),
                inverseMapping: Math.log2,
                min: 1,
                max: 64,
                steps: 12 * 2,
              }),
              threshold: Controls.Slider(0.6, { min: 0, max: 10, step: 0.01 }),
              downsample: Controls.Slider(1, {
                mapping: (x) => Math.pow(2, x),
                inverseMapping: Math.log2,
                min: 1,
                max: 16,
                steps: 4,
              }),
              blur: Controls.Section(
                {
                  passes: Controls.Slider(1, { min: 1, max: 4, step: 1 }),
                  kernelSize: Controls.Select(13, { options: [5, 9, 13] }),
                },
                {
                  label: 'Gaussian blur parameters',
                },
              ),
            },
            { expanded: false },
          ),
        }),
        {
          containerCSS: `position: fixed; top: 0; right: 0;`,
        },
      );

      regl = createREGL({
        canvas: canvas.value,
        // extensions: ['angle_instanced_arrays'],
      });

      const sky = createSkyCommand1(regl, 1);
      const drawMain = await createTextCommand(regl, canvas.value);

      // 创建 frame buffer，用于 post process
      const mainFBO = regl.framebuffer({
        color: regl.texture({
          radius: 1,
        }),
        // depth: true,
      });
      const bloomFBOs = Array(2)
        .fill()
        .map(() =>
          regl.framebuffer({
            color: regl.texture({ radius: 1, mag: 'linear' }),
          }),
        );

      let time = 0;
      const setup = regl({
        uniforms: {
          model: mat4.identity([]),
          time: () => {
            return time;
          },
        },
        // draw 输出到 mainFBO
        // framebuffer: mainFBO,
        depth: { enable: false },
        blend: {
          enable: true,
          func: {
            srcRGB: 'src alpha',
            srcAlpha: 'src alpha',
            dstRGB: 'dst alpha',
            dstAlpha: 'dst alpha',
          },
        },
      });

      const composite = regl({
        frag: bloomFrag,
        uniforms: {
          src: regl.prop('src'),
          bloom: regl.prop('bloom'),
          strength: regl.prop('strength'),
          time: () => time,
        },
      });

      let t;
      let c = 0;
      const update = ({ viewportWidth, viewportHeight, pixelRatio, time: reglTime }) => {
        mainFBO.resize(viewportWidth, viewportHeight);
        bloomFBOs.forEach((fbo) => fbo.resize(viewportWidth, viewportHeight));

        time = reglTime;

        setup({}, () => {
          sky({ p: animation.p });
          drawMain({ p: animation.p });
        });

        if (!t) {
          t = time;
        }
        c += 1;
        if (c >= 60) {
          const fps = c / (time - t);
          c = 0;
          t = time;
          stats.value.textContent = fps;
        }

        // camera.tick();
      };
      regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img, stats, animation, pController };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0

.stats
  z-index 2
  color #fff
  width 100%
  padding 0 10px
  display flex
  justify-content space-between
  color cyan

.p-controller
  width 100%
  height 500vh

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color black
  cursor pointer

  img
    display none

.tips
  width 100%
  display flex
  align-items flex-end
  justify-content center
  pointer-events none
  top auto
  bottom 0
  color yellow
</style>
