import frag from './luma.frag';

/**
 * @param {import('regl')} regl
 */
export function createLumaCommand(regl) {
  return regl({
    frag,
    uniforms: {
      src: regl.prop('src'),
      threshold: regl.prop('threshold'),
    },
    framebuffer: regl.prop('dst'),
  });
}
