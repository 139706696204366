import vert from './bg.vert';
import frag from './bg.frag';
import mat4 from 'gl-mat4';

export function createBgCommand(regl) {
  const finalPosition = [];
  const size = 7;
  const minSize = 1;

  const scatterCount = 2000;
  for (let i = 0; i < scatterCount; i++) {
    const angle = Math.random() * 2 * Math.PI * i;
    const amplitude = Math.random();
    const z = Math.random();
    const w = Math.random();
    finalPosition.push([
      Math.abs(amplitude * Math.cos(angle)),
      Math.abs(amplitude * Math.sin(angle)),
      z,
      w,
    ]);
  }

  // 像素起始随机坐标
  const originPosition = finalPosition.map(() => {
    const angle = Math.random() * 2 * Math.PI;
    const amplitude = Math.random();
    return [amplitude * Math.sin(angle), amplitude * Math.cos(angle), 0, 0].map((n) => n * 20);
  });
  console.log(mat4.create());

  return regl({
    vert,
    frag,
    attributes: {
      a_finalPosition: finalPosition,
      a_originPosition: originPosition,
    },
    uniforms: {
      size,
      minSize,
      u_t: ({ time }) => time,
      u_p: regl.prop('p'),
    },
    count: finalPosition.length,
    primitive: 'points',
  });
}
